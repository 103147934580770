import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import "./index.scss";

const IconFont = Loader.loadBaseComponent("IconFont");

class InfiniteScrollLayout extends React.Component {
  static propTypes = {
    itemWidth: PropTypes.number.isRequired, //用于计算一行能放多少个，它的值不会用于宽度
    itemHeight: PropTypes.number.isRequired, // 用于计算滚动条的高度
    height: PropTypes.number, //容器高度
    count: PropTypes.number.isRequired, // 数据的总数
    data: PropTypes.array.isRequired,
    loadMore: PropTypes.func, //加载更多的方法，次方法应该修改props.data
    renderItem: PropTypes.func,
    rowClass: PropTypes.string,
    threshold: PropTypes.number,
    itemClass: PropTypes.string,
    rowCount: PropTypes.number,
    pdWidth: PropTypes.number
  };
  static defaultProps = {
    itemWidth: 300,
    itemHeight: 200,
    height: 500,
    pdWidth: 0
  };
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.scrollEle = React.createRef();
    this.renderData = [];
    this.infiniteRef = React.createRef();
    this.scrollTimer = null;
    this.state = {
      isInit: false,
      showBackTop: false,
      rowSize: 1
    };
  }
  componentDidMount() {
    const { itemWidth, pdWidth = 0, rowCount } = this.props;
    if (rowCount) {
      this.setState({ rowSize: rowCount, isInit: true });
    } else {
      const { width } = this.scrollEle.current.getBoundingClientRect();
      const rowSize = Math.floor((width - pdWidth * 2) / itemWidth);
      this.setState({ rowSize, isInit: true });
      window.addEventListener("resize", this.bindResizeEvent, false);
    }
  }

  componentWillReceiveProps(props, state) {
    const { itemWidth, pdWidth = 0, rowCount } = props;
    if (rowCount) {
      rowCount !== state.rowSize && this.setState({ rowSize: rowCount });
    } else {
      const { width } = this.scrollEle.current.getBoundingClientRect();
      const rowSize = Math.floor((width - pdWidth * 2) / itemWidth);
      if (rowSize !== state.rowSize) {
        this.setState({ rowSize });
      }
    }
    if (!Utils.isEqual(props.data, this.props.data)) {
      setTimeout(() => {
        this.forceUpdateGrid();
      }, 10);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimer);
    this.listRef = null;
    this.renderData = null;
    this.scrollEle = null;
    this.scrollTimer = null;
    !this.props.rowCount && window.removeEventListener("resize", this.bindResizeEvent, false);
  }

  forceUpdateGrid() {
    this.infiniteRef.current && this.infiniteRef.current._registeredChild.forceUpdateGrid();
  }

  scrollToPosition(...args) {
    this.infiniteRef.current && this.infiniteRef.current._registeredChild.scrollToPosition(...args);
  }

  bindResizeEvent = () => {
    const { itemWidth, pdWidth = 0 } = this.props;
    const { width } = this.scrollEle.current.getBoundingClientRect();
    const rowSize = Math.floor((width - pdWidth * 2) / itemWidth);
    this.setState({ rowSize });
  };

  loadMore = async () => {
    const { loadMore, hasLoadMore = true } = this.props;
    if (hasLoadMore) {
      return loadMore && loadMore();
    }
  };
  onScroll = options => {
    clearTimeout(this.scrollTimer);
    this.scrollTimer = setTimeout(() => {
      const flag = options.scrollTop > 200;
      if (this.state.showBackTop !== flag) {
        this.setState({ showBackTop: flag });
      }
      this.props.onScroll && this.props.onScroll(options);
    }, 500);
  };

  // 渲染列表项
  rowRender = ({ key, index, style }) => {
    const { renderItem, renderSkeleton, rowClass = "", itemClass = "", hasLoadMore, itemWidth, itemHeight } = this.props;
    const { rowSize } = this.state;
    let rowData = this.renderData[index] || [];
    let len = rowSize - rowData.length;

    if (rowData.length < rowSize) {
      for (let i = 0; i < len; i++) {
        rowData.push(null);
      }
    }
    return (
      <div key={key} style={{ padding: `0`, ...style }} className={`row-group-part ${rowClass}`}>
        {rowData.map((v, i) => (
          <div
            key={`${key}-${i}`}
            className={`row-item-part ${v === null && hasLoadMore ? "row-item-placeholder" : ""} ${v !== null ? itemClass : ""}`}
            style={{ width: itemWidth, height: itemHeight }}
          >
            {v !== null ? renderItem(v, i) : hasLoadMore && renderSkeleton ? renderSkeleton() : null}
          </div>
        ))}
      </div>
    );
  };
  _isRowLoaded = ({ index }) => {
    return !!this.renderData[index];
  };
  render() {
    const { className = "", itemHeight = 40, data = [], hasBackTop = false, threshold = 2 } = this.props;
    const { rowSize, isInit, showBackTop } = this.state;
    this.renderData = Utils.arraySliceForX(data, rowSize);
    return (
      <div ref={this.scrollEle} className={`infinite-scroll-layout ${className}`}>
        {isInit ? (
          <InfiniteLoader
            isRowLoaded={this._isRowLoaded}
            loadMoreRows={this.loadMore}
            rowCount={this.renderData.length + 1}
            ref={this.infiniteRef}
            threshold={threshold}
          >
            {({ onRowsRendered, registerChild }) => {
              return (
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      width={width}
                      onRowsRendered={onRowsRendered}
                      ref={registerChild}
                      height={height}
                      rowCount={this.renderData.length}
                      rowHeight={itemHeight}
                      rowRenderer={this.rowRender}
                      onScroll={this.onScroll}
                    />
                  )}
                </AutoSizer>
              );
            }}
          </InfiniteLoader>
        ) : null}

        {hasBackTop && showBackTop && (
          <Tooltip title="返回顶部" placement="top">
            <div className="scoll-back-top" onClick={() => this.scrollToPosition(0)}>
              <IconFont type="icon-S_Arrow_toTop" />
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default InfiniteScrollLayout;
