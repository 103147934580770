import React from "react";
import { AutoSizer, List } from "react-virtualized";

export default class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }
  componentWillUnmount() {
    this.listRef = null;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data && nextProps.data.length === this.props.data.length) {
      this.forceUpdateGrid();
    }
  }
  forceUpdateGrid = () => {
    this.listRef.current && this.listRef.current.forceUpdateGrid();
  };

  // 渲染列表项
  rowRender = ({ key, index, style }) => {
    const { data, renderItem } = this.props;
    const item = data[index];
    return (
      <div key={key} style={style}>
        {renderItem(item)}
      </div>
    );
  };

  render() {
    const { data, rowHeight = 30, className = "", rowRenderer } = this.props;
    return (
      <AutoSizer className={`list-layout ${className}`}>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowCount={data.length}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer ? rowRenderer : this.rowRender}
            ref={this.listRef}
          />
        )}
      </AutoSizer>
    );
  }
}
